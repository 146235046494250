import React from "react";

export default function TopTextNotification() {
  return (
    <div
      className="jumbotron jumbotron-fluid"
      style={{
        height: `auto`,
        marginBottom: 0,
        padding: 0,
        backgroundColor: `#E5E5E5 !important`,
      }}
    >
      {/*Container containing the text on the image*/}
      <div style={{ textAlign: "center", color: "black" }}>
        <p style={{ marginTop: `10px` }}>
          Obs.. Vi har hatt problemer med vår e-post (contact@padeluniverse.no),
          som gjør at vi ikke har mottatt noe som ble sendt før 27. januar. Prøv
          gjerne igjen, så lover vi å svare denne gangen!
        </p>
      </div>
    </div>
  );
}
